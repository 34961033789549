// Imports
import React from 'react';
import './team.scss';

// Components
import PageHero from '../../blocks/PageHero';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/team-2-2400.png';
import HeroWebP from '../../../assets/photos/general/team-2-2400.webp';
import Harry from '../../../assets/team/harry-500.png';
import HarryWebP from '../../../assets/team/harry-500.webp';
import Will from '../../../assets/team/will2-500.png';
import WillWebP from '../../../assets/team/will2-500.webp';
import James from '../../../assets/team/harry-500.png';
import JamesWebP from '../../../assets/team/james-500.webp';
import Scott from '../../../assets/team/scott-500.png';
import ScottWebP from '../../../assets/team/scott-500.webp';
import Ethan from '../../../assets/team/ethan-500.png';
import EthanWebP from '../../../assets/team/ethan-500.webp';
import Elle from '../../../assets/team/no-profile-500.png';
import ElleWebP from '../../../assets/team/no-profile-500.webp';
import Bali from '../../../assets/team/bali-500.png';
import BaliWebP from '../../../assets/team/bali-500.webp';

// Team component
const Team = () => {

	// Team
	const teamMembers = [
		{
			name: "Harry",
			title: "Head Viking",
			image: {
				png: Harry,
				webp: HarryWebP
			},
			bio: "Harry started working in the theatre at his school at 13 years old he got the event bug. He now has a list of qualifications as long as your arm and is a full event nerd. He site managed his first festival(15,000 cap) at 23 and hasn’t looked back. He normally fills the role of event director and also pushes business growth and expansion.",
			hobbies: [
				"Reading",
				"Walking",
				"Cooking",
				"Tasty Pints With Friends"
			],
			email: "harry@vikingevents.co.uk"
		},
		{
			name: "Gabriella",
			title: "Head Of Finance & Admin",
			image: {
				png: Elle,
				webp: ElleWebP
			},
			bio: "Gabriella heads up the back of house team that keeps the company cogs churning. She deals with all money coming into and out of the firm. She also handles the issuing of contracts to clients and keeping a check on crew training records.",
			hobbies: [
				"Horses",
				"Walking The Dog"
			],
			email: "finance@vikingevents.co.uk"
		},
		{
			name: "Ethan",
			title: "Site & Production Manager",
			image: {
				png: Ethan,
				webp: EthanWebP
			},
			bio: "Ethan studied at the same University as Harry and reached out to do some site crew work. Over the following few years he has shown his knowledge of site work and is now in the process of being trained up by Harry to be a Site Manager & Safety Officer. He is a skilled plant operator and is always thinking days ahead when he is working on site.",
			hobbies: [
				"Collecting Music",
				"DJing",
				"Mountain Biking",
				"Gaming"
			],
			email: "ethan@vikingevents.co.uk"
		},
		{
			name: "Scott",
			title: "Site Manager & Safety Officer",
			image: {
				png: Scott,
				webp: ScottWebP
			},
			bio: "Scott joined the company 2 years ago and has rocketed through the ranks. He manages teams across many sites and is normally the final person to leave handing the site over with the client/venue. He helps people achieve their full potential and is as strong as on ox.",
			hobbies: [
				"Travelling",
				"Eating Chicken Wings",
				"Hockey"
			],
			email: "scott@vikingevents.co.uk"
		},
		{
			name: "Will",
			title: "Head Of Logistics & Technology",
			image: {
				png: Will,
				webp: WillWebP
			},
			bio: "Will started his event life as a lighting technician, he moved over to site work and never looked back! Will has recently had a daughter and is working more from home developing our ODIN system and sorting out the logistics. Harry and Will have been friends and worked together for 17 years.",
			hobbies: [
				"Climbing",
				"Making Things",
				"Making Music"
			],
			email: "will@vikingevents.co.uk"
		},
		{
			name: "James",
			title: "Senior Manager & Staff Trainer",
			image: {
				png: James,
				webp: JamesWebP
			},
			bio: "James has been with Viking from the start, he went to school with Harry & Will and forms part of the senior management team with them. His dedication to delivering on time, safely and to perfection are unmatched. In the warehouse James works on concepts for products we can fabricate and has a moderate Monster addiction.",
			hobbies: [
				"Tasty Pints With Friends",
				"Biking",
				"Travelling",
				"Skiing"
			],
			email: "james@vikingevents.co.uk"
		},
		{
			name: "Jason",
			title: "Head Carpenter & Senior Crew",
			image: {
				png: Elle,
				webp: ElleWebP
			},
			bio: "Jason has been working with us for a year and now heads our carpentry department in the winter season, pushing development of our custom projects and is a senior member of the crew over Summer.",
			hobbies: [],
			email: ""
		},
		{
			name: "George",
			title: "Head Painter & Senior Crew",
			image: {
				png: Elle,
				webp: ElleWebP
			},
			bio: "George joined us 3 years ago and has been on some of our hardest projects, in the winter he heads the painting team keeping our cabins and containers looking fresh for the next season. George also fills in as yard manager as he seems to know where everything is!",
			hobbies: [],
			email: ""
		},
		{
			name: "Bali",
			title: "Head Doggo",
			image: {
				png: Bali,
				webp: BaliWebP
			},
			bio: "Bali enjoys long walks across site, borking at squirrels and loves a buggy ride! He is the best site boy and can cheer up anyone having a bad day! His love and affection can be bought with a variety of treats and snacks. During live days he can be seen back stage napping in the office.",
			hobbies: [
				"Borking",
				"Snacks"
			],
			email: ""
		}
	]

	// Return component
	// TODO: Add description meta
	return (

		<div className="team">

			<Meta title="Our Team" description="Meet the Viking team. These are the people that make everything happen and make dreams become realities." pathname="team" image="default" />

			<PageHero title="Our Team" image={{png: Hero, webp: HeroWebP}} />

			<div className="team-wrapper">

				{teamMembers.map((member, index) => (

					<div className="team-member" key={index}>

						<div className="team-member-image">

							<picture>
								<source type="image/webp" srcSet={member.image.webp} width="250" height="300" />
								<source type="image/png" srcSet={member.image.png} width="250" height="300" />
								<img src={member.image.png} alt={member.name} width="250" height="300" />
							</picture>

						</div>

						<div className="team-member-content">

							<h3>{member.name}</h3>

							<h4>{member.title}</h4>

							<p>{member.bio}</p>

							<p>
								{member.hobbies.length > 0 && <strong>Hobbies:</strong>}
								{member.hobbies.map((hobby, hIndex) => (<span key={hIndex}>{hobby}</span>))}
							</p>

							{(member.email !== "") ?
								<a href={`mailto:${member.email}`} target="_blank">
									<button>Contact {member.name.split(" ")[0]}</button>
								</a>
							:
								""
							}
							

						</div>

					</div>

				))}

			</div>

		</div>

    )

};

// Export Component
export default Team;

