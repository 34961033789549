// Imports
import React from 'react';
import { NavLink } from 'react-router-dom';
import './footer.scss';

// Assets
import LogoWhite from '../../assets/logos/logo-white-80.png';
import LogoWhiteWebP from '../../assets/logos/logo-white-80.webp';
import FacebookIcon from '../../assets/icons/facebook-icon-32.png';
import FacebookIconWebP from '../../assets/icons/facebook-icon-32.webp';
import InstagramIcon from '../../assets/icons/instagram-icon-32.png';
import InstagramIconWebP from '../../assets/icons/instagram-icon-32.webp';
import TwitterIcon from '../../assets/icons/twitter-icon-32.png';
import TwitterIconWebP from '../../assets/icons/twitter-icon-32.webp';
import TikTokIcon from '../../assets/icons/tiktok-icon-32.png';
import TikTokIconWebP from '../../assets/icons/tiktok-icon-32.webp';

// Footer component
const Footer = () => {

	// Socials
	const socials = [
		{
			name: "Instagram",
			link: "https://instagram.com/vikingevents_uk",
			icon: {
				png: InstagramIcon,
				webp: InstagramIconWebP
			}
		},
		{
			name: "TikTok",
			link: "https://tiktok.com/@viking_events",
			icon: {
				png: TikTokIcon,
				webp: TikTokIconWebP
			}
		},
		{
			name: "Facebook",
			link: "https://www.facebook.com/VikingEventsUK",
			icon: {
				png: FacebookIcon,
				webp: FacebookIconWebP
			}
		}
	];

	// Nav
	const nav = [
		{
			name: "Careers",
			link: "/careers"
		},
		{
			name: "Contact",
			link: "/contact"
		},
		{
			name: "Login",
			link: "#"
		},
		{
			name: "Privacy",
			link: "/privacy"
		},
		{
			name: "Terms",
			link: "/terms"
		}
	]

	// Create copyright text
	let copyStart = 2023;
	let copyCurrent = new Date().getFullYear();
	let copyright = `Copyright © ${copyStart} Viking Entertainment Holdings Ltd. All Rights Reserved`;
	if (copyStart !== copyCurrent) {
		copyright = `Copyright © ${copyStart} - ${copyCurrent} Viking Entertainment Holdings Ltd. All Rights Reserved`;
	}

	return (

		<div className="footer">

            <div className="footer-left">

				<div className="footer-logo">
					<picture>
						<source type="image/webp" srcSet={LogoWhiteWebP} width="80" height="80" />
						<source type="image/png" srcSet={LogoWhite} width="80" height="80"  />
						<img src={LogoWhite} alt="Viking Logo" width="80" height="80"  />
					</picture>
				</div>

				<div className="footer-copyright">{copyright}</div>

			</div>

			<div className="footer-right">

				<div className="footer-nav">

					<ul className="footer-nav-list">
					{nav.map((item, index) => (

						<li key={index} className="footer-nav-list-item"><NavLink to={item.link}>{item.name}</NavLink></li>

					))}
					</ul>

				</div>

				<div className="footer-socials">

					{socials.map((social, index) => (
						<div className="footer-social" key={index}>
							<NavLink to={social.link} target="_blank">
								<picture>
									<source type="image/webp" srcSet={social.icon.webp} width="32" height="32" />
									<source type="image/png" srcSet={social.icon.png} width="32" height="32" />
									<img src={social.icon.png} alt={social.name} width="32" height="32" />
								</picture>
							</NavLink>
						</div>
					))}

				</div>

			</div>

		</div>

	);

};

export default Footer;
