//import HomeHero from '../assets/photos/home-hero.png';
//import HomeHeroWebP from '../assets/photos/home-hero.webp';

export default [
    
];

/*

{
        id: "some-very-interesting-and-captivating-news-article-1",
        title: "Some very interesting and captivating news article 1",
        image: {
            webp: HomeHeroWebP,
            png: HomeHero,
        },
        date: "12th January 2023",
        content: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius, lectus eu aliquet varius, risus lectus cursus massa, quis iaculis nulla metus quis velit. Aenean convallis mollis risus, sed sollicitudin erat lacinia quis. Integer hendrerit lorem id ligula accumsan, ac varius metus aliquet. Etiam pretium, odio vel imperdiet auctor, sapien orci venenatis mauris, quis porta erat sapien faucibus dolor. Curabitur maximus mi a nisl tincidunt, rhoncus venenatis augue vehicula.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius, lectus eu aliquet varius, risus lectus cursus massa, quis iaculis nulla metus quis velit. Aenean convallis mollis risus, sed sollicitudin erat lacinia quis. Integer hendrerit lorem id ligula accumsan, ac varius metus aliquet. Etiam pretium, odio vel imperdiet auctor, sapien orci venenatis mauris, quis porta erat sapien faucibus dolor. Curabitur maximus mi a nisl tincidunt, rhoncus venenatis augue vehicula.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius, lectus eu aliquet varius, risus lectus cursus massa, quis iaculis nulla metus quis velit. Aenean convallis mollis risus, sed sollicitudin erat lacinia quis. Integer hendrerit lorem id ligula accumsan, ac varius metus aliquet. Etiam pretium, odio vel imperdiet auctor, sapien orci venenatis mauris, quis porta erat sapien faucibus dolor. Curabitur maximus mi a nisl tincidunt, rhoncus venenatis augue vehicula."
        ]
    },

    */