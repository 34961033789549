// Imports
import React from 'react';
import './gallery.scss';
import ImageGallery from "react-image-grid-gallery";

// Components
import PageHero from '../../blocks/PageHero';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/gallery-2400.png';
import HeroWebP from '../../../assets/photos/general/gallery-2400.webp';
import Gallery1 from '../../../assets/photos/gallery/gallery-1.png';
import Gallery2 from '../../../assets/photos/gallery/gallery-2.png';
import Gallery3 from '../../../assets/photos/gallery/gallery-3.png';
import Gallery4 from '../../../assets/photos/gallery/gallery-4.png';
import Gallery5 from '../../../assets/photos/gallery/gallery-5.png';
import Gallery6 from '../../../assets/photos/gallery/gallery-6.png';
import Gallery7 from '../../../assets/photos/gallery/gallery-7.png';
import Gallery8 from '../../../assets/photos/gallery/gallery-8.png';
import Gallery9 from '../../../assets/photos/gallery/gallery-9.png';
import Gallery10 from '../../../assets/photos/gallery/gallery-10.png';
import Gallery11 from '../../../assets/photos/gallery/gallery-11.png';
import Gallery12 from '../../../assets/photos/gallery/gallery-12.png';
import Gallery13 from '../../../assets/photos/gallery/gallery-13.png';
import Gallery14 from '../../../assets/photos/gallery/gallery-14.png';
import Gallery15 from '../../../assets/photos/gallery/gallery-15.png';
import Gallery16 from '../../../assets/photos/gallery/gallery-16.png';
import Gallery17 from '../../../assets/photos/gallery/gallery-17.png';
import Gallery18 from '../../../assets/photos/gallery/gallery-18.png';
import Gallery19 from '../../../assets/photos/gallery/gallery-19.png';
import Gallery20 from '../../../assets/photos/gallery/gallery-20.png';
import Gallery21 from '../../../assets/photos/gallery/gallery-21.png';
import Gallery22 from '../../../assets/photos/gallery/gallery-22.png';
import Gallery23 from '../../../assets/photos/gallery/gallery-23.png';
import Gallery24 from '../../../assets/photos/gallery/gallery-24.png';
import Gallery25 from '../../../assets/photos/gallery/gallery-25.png';
import Gallery26 from '../../../assets/photos/gallery/gallery-26.png';
import Gallery27 from '../../../assets/photos/gallery/gallery-27.png';
import Gallery28 from '../../../assets/photos/gallery/gallery-28.png';
import Gallery29 from '../../../assets/photos/gallery/gallery-29.png';
import Gallery30 from '../../../assets/photos/gallery/gallery-30.png';
import Gallery31 from '../../../assets/photos/gallery/gallery-31.png';
import Gallery32 from '../../../assets/photos/gallery/gallery-32.png';
import Gallery33 from '../../../assets/photos/gallery/gallery-33.png';
import Gallery34 from '../../../assets/photos/gallery/gallery-34.png';
import Gallery35 from '../../../assets/photos/gallery/gallery-35.png';
import Gallery36 from '../../../assets/photos/gallery/gallery-36.png';
import Gallery37 from '../../../assets/photos/gallery/gallery-37.png';
import Gallery38 from '../../../assets/photos/gallery/gallery-38.png';
import Gallery39 from '../../../assets/photos/gallery/gallery-39.png';
import Gallery40 from '../../../assets/photos/gallery/gallery-40.png';
import Gallery41 from '../../../assets/photos/gallery/gallery-41.png';
import Gallery42 from '../../../assets/photos/gallery/gallery-42.png';
import Gallery43 from '../../../assets/photos/gallery/gallery-43.png';
import Gallery44 from '../../../assets/photos/gallery/gallery-44.png';
import Gallery45 from '../../../assets/photos/gallery/gallery-45.png';
import Gallery46 from '../../../assets/photos/gallery/gallery-46.png';
import Gallery47 from '../../../assets/photos/gallery/gallery-47.png';
import Gallery48 from '../../../assets/photos/gallery/gallery-48.png';
import Gallery49 from '../../../assets/photos/gallery/gallery-49.png';
import Gallery50 from '../../../assets/photos/gallery/gallery-50.png';
import Gallery51 from '../../../assets/photos/gallery/gallery-51.png';
import Gallery52 from '../../../assets/photos/gallery/gallery-52.png';
import Gallery53 from '../../../assets/photos/gallery/gallery-53.png';
import Gallery54 from '../../../assets/photos/gallery/gallery-54.png';
import Gallery55 from '../../../assets/photos/gallery/gallery-55.png';
import Gallery56 from '../../../assets/photos/gallery/gallery-56.png';
import Gallery57 from '../../../assets/photos/gallery/gallery-57.png';
import Gallery58 from '../../../assets/photos/gallery/gallery-58.png';
import Gallery59 from '../../../assets/photos/gallery/gallery-59.png';
import Gallery60 from '../../../assets/photos/gallery/gallery-60.png';
import Gallery61 from '../../../assets/photos/gallery/gallery-61.png';

// Gallery component
const Gallery = () => {

    const imagesArray = [
        { alt: "", caption: "", src: Gallery1 },
        { alt: "", caption: "", src: Gallery2 },
        { alt: "", caption: "", src: Gallery3 },
        { alt: "", caption: "", src: Gallery4 },
        { alt: "", caption: "", src: Gallery5 },
        { alt: "", caption: "", src: Gallery6 },
        { alt: "", caption: "", src: Gallery7 },
        { alt: "", caption: "", src: Gallery8 },
        { alt: "", caption: "", src: Gallery9 },
        { alt: "", caption: "", src: Gallery10 },
        { alt: "", caption: "", src: Gallery11 },
        { alt: "", caption: "", src: Gallery12 },
        { alt: "", caption: "", src: Gallery13 },
        { alt: "", caption: "", src: Gallery14 },
        { alt: "", caption: "", src: Gallery15 },
        { alt: "", caption: "", src: Gallery16 },
        { alt: "", caption: "", src: Gallery17 },
        { alt: "", caption: "", src: Gallery18 },
        { alt: "", caption: "", src: Gallery19 },
        { alt: "", caption: "", src: Gallery20 },
        { alt: "", caption: "", src: Gallery21 },
        { alt: "", caption: "", src: Gallery22 },
        { alt: "", caption: "", src: Gallery23 },
        { alt: "", caption: "", src: Gallery24 },
        { alt: "", caption: "", src: Gallery25 },
        { alt: "", caption: "", src: Gallery26 },
        { alt: "", caption: "", src: Gallery27 },
        { alt: "", caption: "", src: Gallery28 },
        { alt: "", caption: "", src: Gallery29 },
        { alt: "", caption: "", src: Gallery30 },
        { alt: "", caption: "", src: Gallery31 },
        { alt: "", caption: "", src: Gallery32 },
        { alt: "", caption: "", src: Gallery33 },
        { alt: "", caption: "", src: Gallery34 },
        { alt: "", caption: "", src: Gallery35 },
        { alt: "", caption: "", src: Gallery36 },
        { alt: "", caption: "", src: Gallery37 },
        { alt: "", caption: "", src: Gallery38 },
        { alt: "", caption: "", src: Gallery39 },
        { alt: "", caption: "", src: Gallery40 },
        { alt: "", caption: "", src: Gallery41 },
        { alt: "", caption: "", src: Gallery42 },
        { alt: "", caption: "", src: Gallery43 },
        { alt: "", caption: "", src: Gallery44 },
        { alt: "", caption: "", src: Gallery45 },
        { alt: "", caption: "", src: Gallery46 },
        { alt: "", caption: "", src: Gallery47 },
        { alt: "", caption: "", src: Gallery48 },
        { alt: "", caption: "", src: Gallery49 },
        { alt: "", caption: "", src: Gallery50 },
        { alt: "", caption: "", src: Gallery51 },
        { alt: "", caption: "", src: Gallery52 },
        { alt: "", caption: "", src: Gallery53 },
        { alt: "", caption: "", src: Gallery54 },
        { alt: "", caption: "", src: Gallery55 },
        { alt: "", caption: "", src: Gallery56 },
        { alt: "", caption: "", src: Gallery57 },
        { alt: "", caption: "", src: Gallery58 },
        { alt: "", caption: "", src: Gallery59 },
        { alt: "", caption: "", src: Gallery60 },
        { alt: "", caption: "", src: Gallery61 }
    ];
    imagesArray.reverse()

	// Return component
	return (

		<div className="gallery">

			<Meta title="Gallery" description="Your top trump. The Ace in your hand. We are the group of people that will be by your side, day or night to craft your vision into a physical reality. " pathname="gallery" image="default" />

			<PageHero title="Gallery" image={{png: Hero, webp: HeroWebP}} />

			<div className="gallery-content">

                <ImageGallery imgArray={imagesArray} columnWidth={230} gapSize={24} />

			</div>

		</div>

    )

};

// Export Component
export default Gallery;
