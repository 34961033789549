// Imports
import React from 'react';
import './about.scss';

// Components
import PageHero from '../../blocks/PageHero';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/show-5-2400.png';
import HeroWebP from '../../../assets/photos/general/show-5-2400.webp';

// About component
const About = () => {

	const stats = [
		{
			year: "2021",
			stats: [
				{
					title: "Events Managed",
					value: "17"
				},
				{
					title: "Crew Supplied",
					value: "973"
				},
				{
					title: "Barrier Built",
					value: "9.7km"
				},
			]
		},
		{
			year: "2022",
			stats: [
				{
					title: "Events Managed",
					value: "27"
				},
				{
					title: "Crew Supplied",
					value: "2137"
				},
				{
					title: "Barrier Built",
					value: "20.1km"
				},
			]
		},
		{
			year: "2023",
			stats: [
				{
					title: "Events Managed",
					value: "47"
				},
				{
					title: "Crew Supplied",
					value: "3789"
				},
				{
					title: "Barrier Built",
					value: "34.7km"
				},
			]
		}
	]

	// Return component
	// TODO: Add description meta
	return (

		<div className="about">

			<Meta title="Who Are We?" description="Welcome to Viking Events, a company that understands, cares and works harder than you could ever imagine. " pathname="about" image="default" />

			<PageHero title="Who Are We?" image={{png: Hero, webp: HeroWebP}} />

			<div className="about-summary">

				<p>Welcome to Viking Events, a company that understands, cares and works harder than you could ever imagine. We specialise in providing comprehensive festival services, from layout design and stage management to health and safety compliance and risk assessment. Our team is dedicated to ensuring that your festival runs smoothly, safely and is a great success. We understand that customer experience starts from the moment they purchase a ticket all the way to getting them home safely afterwards.</p>

				<p>At Viking Events, we take great pride in our attention to detail and our focus on UK health and safety regulations. We understand the importance of providing a safe environment for your festival-goers, and we always put the safety of your audience, performers, and staff first.</p>

				<p>We have extensive knowledge of UK health and safety regulations and are well-versed in the guidance provided by the Purple Guide.</p>

				<p>When it comes to layout design, we understand that the success of a festival is not just about the music or entertainment. It is also about the overall experience of your audience. Our team of experienced professionals is dedicated to creating a layout that maximises the use of space and ensures a smooth flow of traffic throughout the festival grounds. We take into account everything from entry and exit points, to crowd management and the location of key facilities such as medical tents, toilets, and food and drink vendors.</p>

				<p>Over the years, we have worked on a wide variety of festivals, ranging from small intimate events to large-scale productions with over 25,000 attendees. Our experience includes running some of the UK's most high-profile festivals, including Heritage Live, Garage Nation, 51st State Festival and Boundary Brighton. We have built a reputation for excellence and are proud to have received positive feedback from our clients for our attention to detail and dedication to providing exceptional services.</p>

				<p>At Viking Events, we understand that every festival is unique, and we are committed to working closely with you to bring your vision to life. We will work tirelessly to ensure that your festival is a huge success, and we look forward to the opportunity to work with you. Contact us today to learn more about our services and how we can help you create a festival that exceeds your expectations.</p>

			</div>

			<div className="about-values">

				<h3>Our Values</h3>

				<div className="about-values-top">

					<div className="about-values-item">

						<h4>CREATIVITY</h4>

						<p>We understand that people attend events to experience something unique and memorable. That's why we value creativity in every aspect of event planning and management, from initial concept development through to execution. We take pride in our ability to think outside the box and deliver experiences that exceed our client's expectations..</p>

					</div>

					<div className="about-values-item">

						<h4>SAFETY</h4>

						<p>The safety of our clients, staff, and attendees is of utmost importance to us. We adhere to strict health and safety guidelines and work closely with local authorities and safety advisory groups to ensure our events are as safe as possible. We believe that through thorough safety planning we can create more efficient events that deliver an experience like no other.</p>

					</div>

				</div>

				<div className="about-values-bottom">

					<div className="about-values-item">

						<h4>COLLABORATION</h4>

						<p>We understand that successful events require effective collaboration between all parties involved. We work closely with our clients and their teams, as well as with vendors, contractors, and local authorities, to ensure that all aspects of the event are carefully planned and executed to the highest standards. We believe that effective collaboration is key to achieving the best possible outcomes for our clients and creating events that people will remember for years to come.</p>

					</div>

					<div className="about-values-item">

						<h4>INSPIRATION</h4>

						<p>At our company, we believe in inspiring others through our events. We strive to create experiences that not only entertain but also leave a lasting impact on attendees. Whether it's through innovative event design, immersive activations, or meaningful programming, we aim to inspire our clients and their audiences. We also prioritise safety and fun, ensuring that our events not only inspire but also provide a safe and enjoyable environment for everyone involved.</p>

					</div>

				</div>

			</div>

			<div className="about-stats">

				{stats.map((stat, index) => (

					<div className="about-stats-year" key={index}>

						<h4>{stat.year}</h4>

						<div className="about-stats-year-stats">

							{stat.stats.map((stat, sIndex) => (

								<div className="about-stats-year-stats-stat" key={sIndex}>

									<div className="about-stats-year-stats-stat-value">{stat.value}</div>

									<h5>{stat.title}</h5>

								</div>

							))}

						</div>

					</div>

				))}

			</div>

		</div>

    )

};

// Export Component
export default About;
