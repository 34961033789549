// Imports
import React from 'react';
import './consulting.scss';

// Components
import PageHero from '../../blocks/PageHero';
import ContactCTA from '../../blocks/ContactCTA';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/stage-2-2400.png';
import HeroWebP from '../../../assets/photos/general/stage-2-2400.webp';

// Consulting component
const Consulting = () => {

	// Return component
	// TODO: Add description meta
	return (

		<div className="consulting">

			<Meta title="Consulting" description="We can provide consultancy services for new and existing festivals and events in the UK." pathname="what-we-do/consulting" image="default" />

			<PageHero title="Consulting" image={{png: Hero, webp: HeroWebP}} />

			<div className="consulting-content">

				<p>We can provide consultancy services for new and existing festivals and events in the UK. Our team of experienced professionals have a proven track record of delivering successful, safe, and profitable events across a range of sectors and industries.</p>

				<p>We understand that the procurement of each step in the event planning process is critical to the success of any event. That's why we work closely with our clients to identify their specific needs and requirements, and to develop bespoke solutions that meet their unique needs.</p>

				<p>Our consultancy services cover every aspect of event planning, from initial concept development through to final delivery and evaluation. We provide guidance on everything from venue selection and design to logistics, health and safety, and marketing and promotion.</p>

				<p>Our team has extensive experience in the procurement of each step of the event planning process, including vendor selection, contract negotiation, and risk management. We work closely with our clients to ensure that all aspects of the event are carefully planned and managed, and that every detail is considered and accounted for.</p>

				<p>In addition to our focus on safety and profitability, we also prioritise sustainability and environmental responsibility in our consultancy services. We help our clients to develop sustainable event strategies that reduce waste and minimise the environmental impact of their events.</p>

				<p>Overall, our consultancy services are designed to provide our clients with the support and expertise they need to build successful, safe, and profitable events. Whether you're a new or existing event organiser, we invite you to contact us today to learn more about how we can help you achieve your event planning goals.</p>

			</div>

			<ContactCTA />

		</div>

    )

};

// Export Component
export default Consulting;
