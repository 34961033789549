import WW20231 from '../assets/photos/projects/ww-2023/ww-2023-1-2400.png';
import WW20231WebP from '../assets/photos/projects/ww-2023/ww-2023-1-2400.webp';
import WW20231Thumb from '../assets/photos/projects/ww-2023/ww-2023-1-800.png';
import WW20231WebPThumb from '../assets/photos/projects/ww-2023/ww-2023-1-800.webp';
import WW20232 from '../assets/photos/projects/ww-2023/ww-2023-2-2400.png';
import WW20232WebP from '../assets/photos/projects/ww-2023/ww-2023-2-2400.webp';
import WW20233 from '../assets/photos/projects/ww-2023/ww-2023-3-2400.png';
import WW20233WebP from '../assets/photos/projects/ww-2023/ww-2023-3-2400.webp';
import WW20234 from '../assets/photos/projects/ww-2023/ww-2023-4-2400.png';
import WW20234WebP from '../assets/photos/projects/ww-2023/ww-2023-4-2400.webp';
import WW20235 from '../assets/photos/projects/ww-2023/ww-2023-5-2400.png';
import WW20235WebP from '../assets/photos/projects/ww-2023/ww-2023-5-2400.webp';

import Sand1 from '../assets/photos/projects/sandringham-2023/sandringham-2023-1-2400.png';
import Sand1WebP from '../assets/photos/projects/sandringham-2023/sandringham-2023-1-2400.webp';
import Sand1Thumb from '../assets/photos/projects/sandringham-2023/sandringham-2023-1-800.png';
import Sand1WebPThumb from '../assets/photos/projects/sandringham-2023/sandringham-2023-1-800.webp';
import Sand2 from '../assets/photos/projects/sandringham-2023/sandringham-2023-2-2400.png';
import Sand2WebP from '../assets/photos/projects/sandringham-2023/sandringham-2023-2-2400.webp';
import Sand3 from '../assets/photos/projects/sandringham-2023/sandringham-2023-3-2400.png';
import Sand3WebP from '../assets/photos/projects/sandringham-2023/sandringham-2023-3-2400.webp';
import Sand4 from '../assets/photos/projects/sandringham-2023/sandringham-2023-4-2400.png';
import Sand4WebP from '../assets/photos/projects/sandringham-2023/sandringham-2023-4-2400.webp';

import Boundary1 from '../assets/photos/projects/boundary-2022/boundary-2022-1-2400.png';
import Boundary1WebP from '../assets/photos/projects/boundary-2022/boundary-2022-1-2400.webp';
import Boundary1Thumb from '../assets/photos/projects/boundary-2022/boundary-2022-1-800.png';
import Boundary1WebPThumb from '../assets/photos/projects/boundary-2022/boundary-2022-1-800.webp';
import Boundary2 from '../assets/photos/projects/boundary-2022/boundary-2022-2-2400.png';
import Boundary2WebP from '../assets/photos/projects/boundary-2022/boundary-2022-2-2400.webp';
import Boundary3 from '../assets/photos/projects/boundary-2022/boundary-2022-3-2400.png';
import Boundary3WebP from '../assets/photos/projects/boundary-2022/boundary-2022-3-2400.webp';


export default [
    {
        title: "Bavarian Village, Hyde Park Winter Wonderland",
        link: "bavarian-village-2023",
        date: "1st - 16th November 2023",
        thumb: {
            png: WW20231Thumb,
            webp: WW20231WebPThumb
        },
        hero: {
            png: WW20231,
            webp: WW20231WebP
        },
        content: [
            "For the third year in a row we have been asked to provide specialist crew to The Bavarian Village at Hyde Park Winter Wonderland. We provided over 2,400 working hours on site working with their in house tech teams to help install a variety of equipment in the main venue. This included sound, lighting, video, rigging, special effects, power and data. The teams undertook a variety of tasks from unloading trucks to rigging over 300 lights.",
            "We provided a number of skilled staff including Telehandler drivers, all terrain straight mast drivers, forklift drivers, cherry picker drivers, sound engineers, lighting technicians, electricians and general crew.",
            "The job is like no other with the site being live for almost 50 days and working with crews from all over Europe. The days are long and the weather can be absolutely abhorrent but the crew all managed to dig deep and get the show over line with time to spare. It is estimated that during the time it is open the show will host over 1,200,000 people!",
            "We will be back in January to help take the village down again!"
        ],
        gallery: [
            {
                png: WW20231,
                webp: WW20231WebP
            },
            {
                png: WW20232,
                webp: WW20232WebP
            },
            {
                png: WW20233,
                webp: WW20233WebP
            },
            {
                png: WW20234,
                webp: WW20234WebP
            },
            {
                png: WW20235,
                webp: WW20235WebP
            }
        ]
    },
    {
        title: "Heritage Live at The Royal Sandringham Estate",
        link: "heritage-live-sandringham-2023",
        date: "August 2023",
        thumb: {
            png: Sand1Thumb,
            webp: Sand1WebPThumb
        },
        hero: {
            png: Sand1,
            webp: Sand1WebP
        },
        content: [
            "After working with Giles Cooper and the team at GCE Live in 2022, we were asked back for the 2023 to work on their projects with them. Giles has a long running connection with The Royal Family due to him being the Chairman for the Royal Variety Charity. This combined with the shows high production values meant that the royal estate reached out to GCE Live to run a show on their estate. The project started with multiple site visits looking over various options for site layouts, traffic management, CT planning, power management, waste management, concessions management, bars management, the list goes on. We assisted the estate in increasing their licence to 29,999 while we were going over site designs.",
            "The aim was to create a site that allowed the customers to have a view of the stage and the house, while also allowing VIP ticket holders to have access into the Kings private garden.",
            "After a total of 12 site visits, a local residents meeting and more zoom calls than you could imagine we had a full plan for the show. Alongside the team from GCE Live we created a full plan for operational aspects of the show. We worked on many versions of site plans as the event grew in size.",
            "For the pre-production on the show we worked on licencing, EMP, risk assessments, site plans, traffic management, SAG liaison, helicopter movements, CT planning, royalty protection liaison and much more.",
            "The show comprised of 4 separate concert style shows with a single stage. The line up was: ",
            "- Friday - Sir Van Morrison",
            "- Saturday - Robbie Williams",
            '- Sunday - Robbie Williams',
            '- Monday - The Who',
            "On site we provided the following:",
            "- Event Director - Harry",
            "- Safety Officer - Gregg",
            "- Crew Chief - Scott",
            "- Crew - 9 staff",
            "- Pit Barrier",
            "- Signage",
            "The shows were a resounding success with some lessons learned, as is always the way on year one of a show. Having the chance to work on a show like this was a special moment in the history of Viking and we are grateful to have worked with the team from GCE Live on it."
        ],
        gallery: [
            {
                png: Sand1,
                webp: Sand1WebP
            },
            {
                png: Sand2,
                webp: Sand2WebP
            },
            {
                png: Sand3,
                webp: Sand3WebP
            },
            {
                png: Sand4,
                webp: Sand4WebP
            }
        ]
    },
    {
        title: "Boundary Festival 2022",
        link: "boundary-festival-2022",
        date: "September 2022",
        thumb: {
            png: Boundary1Thumb,
            webp: Boundary1WebPThumb
        },
        hero: {
            png: Boundary1,
            webp: Boundary1WebP
        },
        content: [
            "After working on Boundary Festival for a number of years, Viking were asked to take over the management of the show. This included creating a new area for the festival, making more space for customers and making the egress more effective.",
            "Instead of trying to change a site design that we believed had some lingering faults we started from scratch, firstly looking at the stage placement to maximise audience area then looking at how this would impact our space for ingress/egress. The site had a few interesting points, firstly a medium pressure gas main running through it and secondly a section of the total hired area not be covered under the licensing act. Both of these put constraints on what could be put where and this affected the way the site could work. ",
            "The event site also required a local village to have their main access road closed so required a lot of engagement and planning. ",
            "The show ran well with the police silver commander stating that it was one of the best run shows he has ever seen in Brighton. ",
            "We entertained 20,000 people managing the entire build and break process with no incidents taking place.",
            "For the show we provided:",
            "- Event Director ",
            "- Safety Officer ",
            "- Site Manager",
            "- Plant Crew",
            "- Site Crew",
            "- Pit Barrier",
            "- Signage",
            "- Scaffolding",
            "- 8x6 Stage Cover and Deck"
        ],
        gallery: [
            {
                png: Boundary1,
                webp: Boundary1WebP
            },
            {
                png: Boundary2,
                webp: Boundary2WebP
            },
            {
                png: Boundary3,
                webp: Boundary3WebP
            }
        ]
    },
]