// Imports
import React from 'react';
import './eventdesign.scss';

// Components
import PageHero from '../../blocks/PageHero';
import ContactCTA from '../../blocks/ContactCTA';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/site-1-2400.png';
import HeroWebP from '../../../assets/photos/general/site-1-2400.webp';

// EventDesign component
const EventDesign = () => {

	// Return component
	// TODO: Add description meta
	return (

		<div className="event-design">

			<Meta title="Event Design" description="At Viking Events, we believe that excellent event design is the cornerstone of any successful live event. " pathname="what-we-do/event-design" image="default" />

			<PageHero title="Event Design" image={{png: Hero, webp: HeroWebP}} />

			<div className="event-design-content">

				<p>At Viking Events, we believe that excellent event design is the cornerstone of any successful live event. We use industry standard software to develop to-scale site plans which are clear and easy to read. For site plans, we can either come to a clients office or they can come to our state-of-the-art design suite. Don’t worry, we have a very nice coffee machine and refreshments here!</p>

				<p>One of the key aspects of Viking is our ability to create efficient sites that not only boost the customer experience but also save your budget.</p>

				<p>We aim to design away as many potential safety issues as possible, working away from pinch points to ensuring fire exits are going to be effective. Our site plan designers are on hand 24 hours a day 7 days a week, so if you want to see a new option on your site plan we can react to it within a matter of hours, not days.</p>

				<p>Our site plans can go into as much detail as you require, from basic outlines and infrastructure locations all the way through to ingress/egress designs, cable runs, etc.</p>

			</div>

			<ContactCTA />

		</div>

    )

};

// Export Component
export default EventDesign;
