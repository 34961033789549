// Imports
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import './header.scss';

// Assets
import LogoWhite from '../../assets/logos/logo-white-120.png';
import LogoWhiteWebP from '../../assets/logos/logo-white-120.webp';
import MenuIconWhite from '../../assets/icons/menu-icon-white-38.png';
import MenuIconWhiteWebP from '../../assets/icons/menu-icon-white-38.webp';

// Header component
const Header = () => {

	const location = useLocation();

	// State
	const [navExpanded, setNavExpanded] = useState(false);
	const [mobileSubNavIsVisible, setMobileSubNavIsVisible] = useState(false);
	const [mobileNavExpanded, setMobileNavExpanded] = useState(false);
	const [solidHeader, setSolidHeader] = useState(false);
	const [offset, setOffset] = useState(0);

	// Reset mobile nav state
	const resetMobileNav = () => {
		setMobileNavExpanded(false);
		setMobileSubNavIsVisible(false);
	}

	// Reset all nav
	const resetNav = () => {
		setMobileNavExpanded(false);
		setMobileSubNavIsVisible(false);
		setNavExpanded(false);
	}

	// Handle location changes
	useEffect(() => {

		resetNav();

		if (window.location.pathname !== "/") {
			setSolidHeader(true);
		}

		window.scrollTo(0, 0);

	}, [location]);

	// Handle scroll and header changes
	useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

	if (offset > 270 && !solidHeader && window.location.pathname === "/") {
		setSolidHeader(true);
	} else if (offset <= 270 && solidHeader && window.location.pathname === "/") {
		setSolidHeader(false);
	}

	// Return component
	return (

		<div className={(solidHeader) ? "header solid" : "header"}>

			<NavLink to="/">
			<div className="header-logo">
				<picture>
					<source type="image/webp" srcSet={LogoWhiteWebP} width="120" height="120" />
					<source type="image/png" srcSet={LogoWhite} width="120" height="120" />
					<img src={LogoWhite} alt="Viking Events" width="120" height="120" />
				</picture>
			</div>
			</NavLink>

			<div className="header-nav">

				<ul className="header-nav-list">

					<li className="header-nav-list-item" onMouseOver={() => { setNavExpanded(true); }}><NavLink to="/what-we-do">What We Do</NavLink></li>
					<li className="header-nav-list-item"><NavLink to="/about">About</NavLink></li>
					<li className="header-nav-list-item"><NavLink to="/team">Team</NavLink></li>
					<li className="header-nav-list-item"><NavLink to="/news">News</NavLink></li>
					<li className="header-nav-list-item"><NavLink to="/gallery">Gallery</NavLink></li>
					<li className="header-nav-list-item"><NavLink to="/contact">Get In Touch</NavLink></li>

				</ul>

				<div className={(navExpanded) ? "header-nav-expanded expanded" : "header-nav-expanded"} onMouseLeave={() => { setNavExpanded(false) }}>

					<div className="header-nav-expanded-arrow"></div>

					<ul className="header-nav-expanded-list">
						<li className="header-nav-expanded-list-item"><NavLink to="/what-we-do/crew">Crew</NavLink></li>
						<li className="header-nav-expanded-list-item"><NavLink to="/what-we-do/event-design">Event Design</NavLink></li>
						<li className="header-nav-expanded-list-item"><NavLink to="/what-we-do/hire">Hire</NavLink></li>
						<li className="header-nav-expanded-list-item"><NavLink to="/what-we-do/safety">Safety</NavLink></li>
						<li className="header-nav-expanded-list-item"><NavLink to="/what-we-do/emp-sag">EMP & Sag</NavLink></li>
						<li className="header-nav-expanded-list-item"><NavLink to="/what-we-do/consulting">Consulting</NavLink></li>
					</ul>

				</div>

			</div>

			<div className="header-mobile-nav-toggle" onClick={() => { setMobileNavExpanded(!mobileNavExpanded) }}>

				<picture>
					<source type="image/webp" srcSet={MenuIconWhiteWebP} width="38" height="38" />
					<source type="image/png" srcSet={MenuIconWhite} width="38" height="38" />
					<img src={MenuIconWhite} alt="Menu" width="38" height="38" />
				</picture>

			</div>

			<div className={(mobileNavExpanded) ? "header-nav-mobile visible" : "header-nav-mobile"}>

					<div className="header-nav-mobile-arrow"></div>

					<ul className="header-nav-mobile-list">
						<li className="header-nav-mobile-list-item" onClick={() => { setMobileSubNavIsVisible(!mobileSubNavIsVisible)}}>What We Do</li>
						<li>

							<ul className={(mobileSubNavIsVisible) ? "header-nav-mobile-sub-list visible" : "header-nav-mobile-sub-list"}>
								<li className="header-nav-mobile-sub-list-item"><NavLink to="/what-we-do/crew" onClick={() => { resetMobileNav() }}>Crew</NavLink></li>
								<li className="header-nav-mobile-sub-list-item"><NavLink to="/what-we-do/event-design" onClick={() => { resetMobileNav() }}>Event Design</NavLink></li>
								<li className="header-nav-mobile-sub-list-item"><NavLink to="/what-we-do/hire" onClick={() => { resetMobileNav()  }}>Hire</NavLink></li>
								<li className="header-nav-mobile-sub-list-item"><NavLink to="/what-we-do/safety" onClick={() => { resetMobileNav()  }}>Safety</NavLink></li>
								<li className="header-nav-mobile-sub-list-item"><NavLink to="/what-we-do/emp-sag" onClick={() => { resetMobileNav() }}>EMP & Sag</NavLink></li>
								<li className="header-nav-mobile-sub-list-item"><NavLink to="/what-we-do/consulting" onClick={() => { resetMobileNav()  }}>Consulting</NavLink></li>
							</ul>

						</li>
						<li className="header-nav-mobile-list-item"><NavLink to="/about" onClick={() => { resetMobileNav() }}>About</NavLink></li>
						<li className="header-nav-mobile-list-item"><NavLink to="/team" onClick={() => { resetMobileNav() }}>Team</NavLink></li>
						<li className="header-nav-mobile-list-item"><NavLink to="/news" onClick={() => { resetMobileNav() }}>News</NavLink></li>
						<li className="header-nav-mobile-list-item"><NavLink to="/gallery" onClick={() => { resetMobileNav() }}>Gallery</NavLink></li>
						<li className="header-nav-mobile-list-item"><NavLink to="/contact" onClick={() => { resetMobileNav() }}>Get In Touch</NavLink></li>
					</ul>

				</div>

		</div>

	);

};

export default Header;
