// Imports
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import './contact.scss';

// Components
import PageHero from '../../blocks/PageHero';
import Loader from '../../blocks/Loader';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/show-1-2400.png';
import HeroWebP from '../../../assets/photos/general/show-1-2400.webp';

// Contact component
const Contact = () => {

	// Form refs
	const nameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();
	const messageRef = useRef();

	// State
	const [successIsVisible, setSuccessIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// Handle form submit
	const handleFormSubmit = async () => {

		// Get Values
		let nameValue = nameRef.current.value;
		let emailValue = emailRef.current.value;
		let phoneValue = phoneRef.current.value;
		let messageValue = messageRef.current.value;

		if (validateForm(nameValue, emailValue, messageValue)) {

			// Show loader
			setIsLoading(true);

			// Create content for viking email
			let content = `
New contact form submission:

Name: ${nameValue}
Email: ${emailValue}
Phone: ${phoneValue ? phoneValue : "Not Provided"}
Message:

${messageValue}`;

			// Send viking email
			try {

				const response = await axios.post('https://europe-west2-viking-main-web-407620.cloudfunctions.net/viking-function-mail-send ', {
					toName: "Viking",
					toEmail: "info@vikingevents.co.uk",
					subject: "New Contact Form Submission",
					content: content
				});

				if (response.data.error === null) {

					// Send confirmation email
					const confResponse = await axios.post('https://europe-west2-viking-main-web-407620.cloudfunctions.net/viking-function-mail-send ', {
						toName: nameValue,
						toEmail: emailValue,
						subject: 'Thank you for getting in touch!',
						content: 'Thank you for getting in touch with us. We will get back to you as soon as possible!'
					});

					// Log error if confirmation email fails
					if (confResponse.data.error !== null) {
						logError(confResponse.data.error);
					}

					// Hide Loader
					setIsLoading(false);

					// Show Success
					setSuccessIsVisible(true);

				} else {

					// Hide Loader
					setIsLoading(false);

					// Display error
					showError("Something went wrong, please try again soon.");

					// Log error
					logError(response.data.error);

				}

			} catch(error) {

				// Hide Loader
				setIsLoading(false);

				// Display error
				showError("Something went wrong, please try again soon.");

				// Log error
				logError(error);

			}

		}

	}

	// Validate form
	const validateForm = (nameValue, emailValue, messageValue) => {

		// Name
		if (!nameValue) {
			showError("Please enter your name.");
			return false;
		}

		// Email
		if (!validateEmail(emailValue)) {
			showError("Please enter your email address.");
			return false;
		}

		// About
		if (!messageValue) {
			showError("Please tell us what we can do for you.");
			return false;
		}

		return true;

	};

	const validateEmail = (email) => {
		return String(email)
		  .toLowerCase()
		  .match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	const showError = (message) => {
		toast.error(message, {
			position: "bottom-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	}

	// Log error
	const logError = (error) => {
		console.error(error);
		// TODO: Log error properly
	}

	// Return component
	// TODO: Add description meta
	return (

		<div className="contact">

			<Meta title="Contact" description="Whether you are looking for work, someone to help make your event happen or just need to pick our brains, the Viking team are always on hand to help." pathname="contact" image="default" />

			<PageHero title="Get In Touch" image={{png: Hero, webp: HeroWebP}} />

			<div className="contact-content">

				<p>Whether you are looking for work, someone to help make your event happen or just need to pick our brains, the Viking team are always on hand to help.</p>

				<h4>How Can We Help?</h4>

				<div className="contact-form">

					<div className="contact-form-input">
						<label>What should we call you? *</label>
						<input type="text" placeholder="Name" ref={nameRef} />
					</div>

					<div className="contact-form-input">
						<label>How can we reach you? *</label>
						<input type="email" placeholder="email" ref={emailRef} />
						<input type="tel" placeholder="phone" ref={phoneRef} />
					</div>

					<div className="contact-form-input">
						<label>How can we help? *</label>
						<textarea placeholder="What can we do for you?" ref={messageRef} />
					</div>

					<button type="submit" onClick={() => { handleFormSubmit(); }}>SUBMIT</button>

				</div>

			</div>

			{(successIsVisible) ?

				<div className="contact-success">

					<h2>Thank you for getting in touch!</h2>

					<span>We will get back to you as soon as possible!</span>

				</div>

			:
			''}

			{(isLoading) ?
				<Loader />
			:
			''}

			<div className="contact-details">

				<h4>Our Details</h4>

				<div className="contact-details-inner">

					<span><strong>Email:</strong> <a href="mailto:info@vikingevents.co.uk">info@vikingevents.co.uk</a></span>

					<span><strong>Phone:</strong> <a href="tel:02084322265">020 8432 2265</a></span>

				</div>

			</div>

		</div>

    )

};

// Export Component
export default Contact;