// Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './testimonials.scss';

// Testimonials component
const Testimonials = (props) => {

    // State
    const [activeIndex, setActiveIndex] = useState(0);

	// Return component
	return (

		<div className="testimonials">

			{props.testimonials.map((testimonial, index) => (

                <div className={(index === activeIndex) ? "testimonial active" : "testimonial"} key={index}>

                    <p>"{testimonial.text}"</p>

                    <span>{testimonial.client}</span>

                </div>

            ))}

            <div className="testimonial-controls">

                {props.testimonials.map((testimonial, index) => (

                    <div className={(index === activeIndex) ? "testimonial-control active" : "testimonial-control" } key={index} onClick={() => { setActiveIndex(index); }}></div>

                ))}

            </div>

		</div>

    )

};

Testimonials.propTypes = {
    testimonials: PropTypes.array.isRequired
}

// Export Component
export default Testimonials;
