// Imports
import React from 'react';
import './hire.scss';

// Components
import PageHero from '../../blocks/PageHero';
import ContactCTA from '../../blocks/ContactCTA';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/hire-1-2400.png';
import HeroWebP from '../../../assets/photos/general/hire-1-2400.webp';

// Hire component
const Hire = () => {

	// Return component
	// TODO: Add description meta
	return (

		<div className="hire">

			<Meta title="Hire" description="We got bored of trying to find certain products for events either due to them not being a thing yet or there not being a high enough stock level for the event sector." pathname="what-we-do/hire" image="default" />

			<PageHero title="Hire" image={{png: Hero, webp: HeroWebP}} />

			<div className="hire-content">

				<p>We got bored of trying to find certain products for events either due to them not being a thing yet or there not being a high enough stock level for the event sector. So we made our own.</p>

				<p>Starting with Pit Barrier, we quickly realised we could also help the industry with scaffolding(tube and fittings), machines(post whackers, water pumps, etc) and event-specific signage.</p>

				<p>Our custom designed and manufactured signage can be installed by just 2 people and is fully compliant with UK law. The signage requires a third of the amount of scaffolding as traditional signage and can be installed by less people in less time. It provides cost savings across the board and looks better than traditional methods.</p>

				<p>We also work with many suppliers across the UK on our shows, so if we don’t have the item you are looking for, we will know someone that does.</p>

				<p>We prioritise the quality of customer experience over everything else when it comes to equipment hire.</p>

			</div>

			<ContactCTA />

		</div>

    )

};

// Export Component
export default Hire;
