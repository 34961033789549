// Imports
import React from 'react';
import './safety.scss';

// Components
import PageHero from '../../blocks/PageHero';
import ContactCTA from '../../blocks/ContactCTA';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/safety-1-2400.png';
import HeroWebP from '../../../assets/photos/general/safety-1-2400.webp';

// Safety component
const Safety = () => {

	// Return component
	// TODO: Add description meta
	return (

		<div className="safety">

			<Meta title="Safety" description="At Viking Events, we understand that the safety of our staff and attendees is paramount when it comes to any live event. " pathname="what-we-do/safety" image="default" />

			<PageHero title="Safety" image={{png: Hero, webp: HeroWebP}} />

			<div className="safety-content">

				<p>At Viking Events, we understand that the safety of our staff and attendees is paramount when it comes to any live event. That's why we take great care to ensure that our events meet or exceed all UK health and safety regulations.</p>

				<p>Our experienced team is well-versed in the specific requirements of the UK festival and event industry, and we have a deep understanding of the hazards and risks that can be present at these events. We work closely with our clients to identify and mitigate these risks, creating detailed risk assessments and safety plans that are tailored to the specific event.</p>

				<p>In addition to our in-depth knowledge of UK health and safety regulations, we also have experience working with local authorities and emergency services to ensure that we have the necessary permits, licenses, and resources to operate safely and smoothly. We take a proactive approach to health and safety, identifying potential hazards and implementing measures to prevent accidents before they occur.</p>

				<p>Our commitment to health and safety extends to our staff as well. We provide our crew with comprehensive training on health and safety procedures and provide them with the necessary personal protective equipment (PPE) and tools to perform their work safely.</p>

				<p>Overall, our focus on UK health and safety regulations is an integral part of our commitment to delivering exceptional events that are both enjoyable and safe for all attendees and staff. If you're looking for a team that prioritizes safety and compliance, we invite you to contact us today to learn more about our event management services.</p>

			</div>

			<ContactCTA />

		</div>

    )

};

// Export Component
export default Safety;
