// Imports
import React from 'react';
import BounceLoader from "react-spinners/BounceLoader";
import './loader.scss';

// Loader component
const Loader = () => {

	// Return component
	return (

		<div className="loader">

		<BounceLoader
			color="#FFF"
			loading={true}
			size={40}
			aria-label="Loading Spinner"
			data-testid="loader"
		/>

		</div>

    )

};

// Export Component
export default Loader;
