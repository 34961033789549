// Imports
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import './container.scss';

// Views
import PageNotFound from '../views/PageNotFound';
import Home from '../views/Home';
import WhatWeDo from '../views/WhatWeDo';
import About from '../views/About';
import Team from '../views/Team';
import News from '../views/News';
import Contact from '../views/Contact';
import Crew from '../views/Crew';
import EventDesign from '../views/EventDesign';
import Hire from '../views/Hire';
import Safety from '../views/Safety';
import EMPSag from '../views/EMPSag';
import Consulting from '../views/Consulting';
import Careers from '../views/Careers';
import Privacy from '../views/Privacy';
import Terms from '../views/Terms';
import RecentProject from '../views/RecentProject';
import NewsArticle from '../views/NewsArticle';
import Gallery from '../views/Gallery';
import SiteInduction from '../views/SiteInduction';

// Components
import Header from '../Header';
import Footer from '../Footer';

// Container component
const Container = () => {

	return (

        <div>

            <Header />

            <Routes>

                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/team" element={<Team />} />
                <Route path="/news" element={<News />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/recent-project/:title" element={<RecentProject />} />
                <Route path="/news/:id" element={<NewsArticle />} />
                <Route path="/gallery" element={<Gallery />} />

                <Route path="/careers" element={<Careers />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />

                <Route path="/what-we-do" element={<WhatWeDo />} />
                <Route path="/what-we-do/crew" element={<Crew />} />
                <Route path="/what-we-do/event-design" element={<EventDesign />} />
                <Route path="/what-we-do/hire" element={<Hire />} />
                <Route path="/what-we-do/safety" element={<Safety />} />
                <Route path="/what-we-do/emp-sag" element={<EMPSag />} />
                <Route path="/what-we-do/consulting" element={<Consulting />} />

                <Route path="/resources/site-induction" element={<SiteInduction />} />

                <Route path="/404" element={<PageNotFound />} />

                <Route path="*" element={<Navigate to="/404" />} replace />

            </Routes>

            <Footer />

            <ToastContainer />

        </div>

    );

};

export default Container;
