// Imports
import React from 'react';
import './crew.scss';

// Components
import PageHero from '../../blocks/PageHero';
import ContactCTA from '../../blocks/ContactCTA';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/team-3-2400.png';
import HeroWebP from '../../../assets/photos/general/team-3-2400.webp';

// Crew component
const Crew = () => {

	// Return component
	// TODO: Add description meta
	return (

		<div className="crew">

			<Meta title="Crew" description="Festival site crew members are essential to the success of any live event. " pathname="what-we-do/crew" image="default" />

			<PageHero title="Crew" image={{png: Hero, webp: HeroWebP}} />

			<div className="crew-content">

				<p>Festival site crew members are essential to the success of any live event. These hardworking individuals are responsible for a wide range of tasks, including setting up and dismantling stages and other structures, loading and unloading equipment, installing lighting and sound systems, and ensuring that all facilities are operational and safe for festival-goers.</p>

				<p>In addition to their physical work, festival site crew members are also responsible for providing excellent customer service to attendees and performers alike. They are often the first point of contact for guests and are expected to be knowledgeable about the festival's layout, schedule, and amenities.</p>

				<p>Working as a festival site crew member requires a great deal of physical stamina and flexibility, as well as the ability to work well under pressure. Crew members must be able to work long hours in all weather conditions and be able to adapt to changing schedules and last-minute changes.</p>

				<p>We pride ourselves on training our crew from the ground up. We have a specific way of working at Viking, and we expect all our staff to stick to these high standards. We are the first to arrive on-site and the last ones to leave, making sure that the best interests of the event are always looked after. All crew chiefs and managers have gone through their ACT Counter Terror Training courses.</p>

				<p>Overall, festival site crew members play a critical role in ensuring that festivals run smoothly and safely. Their hard work and dedication are essential to creating an unforgettable experience for all involved.</p>

			</div>

			<ContactCTA />

		</div>

    )

};

// Export Component
export default Crew;
