// Imports
import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './learnmore.scss';

// LearnMore component
const LearnMore = (props) => {

    // Create class name
    let className = "learn-more";
    if (props.isDark) {
        className += " isDark";
    }
    if (props.isFlipped) {
        className += " isFlipped";
    }

	// Return component
	return (

		<div className={className}>

            <div className="learn-more-image">

                <picture>
					<source type="image/webp" srcSet={props.content.image.webp} width="560" height="360" />
					<source type="image/png" srcSet={props.content.image.png} width="560" height="360" />
					<img src={props.content.image.png} alt={props.content.title} width="560" height="360" />
				</picture>

                <div className="learn-more-image-cover"></div>

            </div>

            <div className="learn-more-content">

                <h4>{props.content.title}</h4>

                <p>{props.content.text}</p>

                <NavLink to={props.content.link}>
                    <button className="learn-more-button">Learn More</button>
                </NavLink>

            </div>

		</div>

    )

};

LearnMore.propTypes = {
    isDark: PropTypes.bool,
    isFlipped: PropTypes.bool,
    content: PropTypes.object.isRequired
}

// Export Component
export default LearnMore;
