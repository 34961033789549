// Imports
import React from 'react';
import { NavLink } from 'react-router-dom';
import './contactcta.scss';

// ContactCTA component
const ContactCTA = () => {

	// Return component
	return (

		<div className="contact-cta">

            <h2>Make Your Event A Reality</h2>

            <p>Get in touch today and see how our modern and out of the box thinking can help turn your dreams into a reality.</p>

            <NavLink to="/contact">
                <button className="contact-cta-button">Start Now</button>
            </NavLink>

		</div>

    )

};

// Export Component
export default ContactCTA;
