// Imports
import React from 'react';
import './siteinduction.scss';

// Components
import PageHero from '../../blocks/PageHero';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/show-3-2400.png';
import HeroWebP from '../../../assets/photos/general/show-3-2400.webp';

// SiteInduction component
const SiteInduction = () => {

	// Return component
	return (

		<div className="siteinduction">

			<Meta title="Resources - Site Induction" pathname="resources/site-induction" image="default" description="" />

            <div className="siteinduction-video">

                <video width="80%" height="80%" src="https://viking-assets-public.s3.amazonaws.com/media/video/site-induction.mp4" controls />

            </div>

		</div>

    )

};

// Export Component
export default SiteInduction;
