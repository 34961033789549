// Imports
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './news.scss';

// Utils
import newsArticles from '../../../utils/newsArticles';

// Components
import PageHero from '../../blocks/PageHero';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/general-1-2400.png';
import HeroWebP from '../../../assets/photos/general/general-1-2400.webp';

// News component
const News = () => {

	const navigate = useNavigate();

	// Navigate to article
	const navigateToArticle = (article) => {

		navigate(`/news/${article.id}`);

	}

	// Return component
	// TODO: Add description meta
	return (

		<div className="news">

			<Meta title="News" description="Coming Soon." pathname="news" image="default" />

			<PageHero title="News" image={{png: Hero, webp: HeroWebP}} />

			<div className="news-articles">

				{(newsArticles.length === 0) ?
				<span>No news available at the moment.</span>
				:
				newsArticles.map((article, index) => (

					<div className="news-article" key={index}>

						<div className="news-article-image">

							<picture>
								<source srcSet={article.image.webp} type="image/webp" />
								<source srcSet={article.image.png} type="image/png" />
								<img src={article.image.png} alt={article.title} />
							</picture>

						</div>

						<h3>{article.title}</h3>

						<span>{article.date}</span>

						<p>{article.content.join(" ").substring(0,500)}...</p>

						<button onClick={() => { navigateToArticle(article); }}>Read More</button>

					</div>

				))}

			</div>

		</div>

    )

};

// Export Component
export default News;
