// Imports
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import './careers.scss';

// Components
import PageHero from '../../blocks/PageHero';
import Loader from '../../blocks/Loader';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/team-1-2400.png';
import HeroWebP from '../../../assets/photos/general/team-1-2400.webp';

// Careers component
const Careers = () => {

	// State
	const [formIsVisible, setFormIsVisible] = useState(false);
	const [successIsVisible, setSuccessIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const showForm = () => {
		setFormIsVisible(true);
		setSuccessIsVisible(false);
	}

	// Licenses Input data
	const licences = [
		{
			value: "Cat B",
			ref: useRef()
		},
		{
			value: "Cat BE",
			ref: useRef()
		},
		{
			value: "Cat C1",
			ref: useRef()
		},
		{
			value: "Cat C1E",
			ref: useRef()
		},
		{
			value: "Cat C",
			ref: useRef()
		},
		{
			value: "Cat CE",
			ref: useRef()
		},
		{
			value: "Cat D1",
			ref: useRef()
		},
		{
			value: "IPAF 3A",
			ref: useRef()
		},
		{
			value: "IPAF 3B",
			ref: useRef()
		},
		{
			value: "Forklift",
			ref: useRef()
		},
		{
			value: "Telehandler",
			ref: useRef()
		}
	]

	// Form refs
	const nameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();
	const aboutRef = useRef();
	const additionalRef = useRef();

	// Handle form submit
	const handleFormSubmit = async () => {

		// Get Values
		let nameValue = nameRef.current.value;
		let emailValue = emailRef.current.value;
		let phoneValue = phoneRef.current.value;
		let aboutValue = aboutRef.current.value;
		let additionalValue = additionalRef.current.value;
		let licensesValue = [];
		licences.forEach(licence => {
			if (licence.ref.current.checked) {
				licensesValue.push(licence.value);
			}
		});

		if (validateForm(nameValue, emailValue, aboutValue)) {

			// Show loader
			setIsLoading(true);

			// Create content for viking email
			let content = `
New freelancer application:

Name: ${nameValue}
Email: ${emailValue}
Phone: ${phoneValue ? phoneValue : "Not Provided"}
About:

${aboutValue}

Additional:

${additionalValue ? additionalValue : "Not Provided"}

Licenses:

`;

			if (licensesValue.length === 0) {
				content += "None Selected";
			} else {
				licensesValue.forEach(license => {
					content += `${license}, `;
				});
			}

			// Send viking email
			try {

				const response = await axios.post('https://europe-west2-viking-main-web-407620.cloudfunctions.net/viking-function-mail-send', {
					toName: "Viking",
					toEmail: "info@vikingevents.co.uk",
					subject: "New Freelancer Application",
					content: content
				});

				if (response.data.error === null) {

					// Send confirmation email
					const confResponse = await axios.post('https://europe-west2-viking-main-web-407620.cloudfunctions.net/viking-function-mail-send ', {
						toName: nameValue,
						toEmail: emailValue,
						subject: 'Thank you for your interest!',
						content: 'Thank you for your interest in joining us as a freelancer. We will get back to you as soon as possible!'
					});

					// Log error if confirmation email fails
					if (confResponse.data.error !== null) {
						logError(confResponse.data.error);
					}

					// Hide Loader
					setIsLoading(false);

					// Hide form and show success message
					setFormIsVisible(false);
					setSuccessIsVisible(true);

				} else {

					// Hide Loader
					setIsLoading(false);

					// Display error
					showError("Something went wrong, please try again soon.");

					// Log error
					logError(response.data.error);

				}

			} catch(error) {

				// Hide Loader
				setIsLoading(false);

				// Display error
				showError("Something went wrong, please try again soon.");

				// Log error
				logError(error);

			}

		}

	}

	// Validate form
	const validateForm = (nameValue, emailValue, aboutValue) => {

		// Name
		if (!nameValue) {
			showError("Please enter your name.");
			return false;
		}

		// Email
		if (!validateEmail(emailValue)) {
			showError("Please enter your email address.");
			return false;
		}

		// About
		if (!aboutValue) {
			showError("Please tell us about yourself.");
			return false;
		}

		return true;

	};

	const validateEmail = (email) => {
		return String(email)
		  .toLowerCase()
		  .match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	const showError = (message) => {
		toast.error(message, {
			position: "bottom-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	}

	// Log error
	const logError = (error) => {
		console.error(error);
		// TODO: Log error properly
	}

	// Return component
	return (

		<div className="careers">

			<Meta title="Careers" description="As a rapidly expanding company in the events sector, we are continuously seeking to enlarge our team of skilled professionals. " pathname="careers" image="default" />

			<PageHero title="Careers" image={{png: Hero, webp: HeroWebP}} />

			<div className="careers-content">

				<p>As a rapidly expanding company in the events sector, we are continuously seeking to enlarge our team of skilled professionals. We provide all required training, personal protective equipment, and essential tools to ensure that our crew is equipped to handle any situation. Our company is recognised as one of the highest-paying organisations in the industry, and we are proud of our commitment to the well-being of our staff.</p>

				<p>We take our responsibility to our employees seriously and are dedicated to offering recognised training packages to those who show an interest in developing their career within the events industry.</p>

				<p>As a company that operates seasonally and covers vast distances, we always welcome applications from professionals of all skill sets during peak periods. With our expanding off-season portfolio, we frequently recruit individuals who are enthusiastic about joining our team. Although our primary base of operations is located in the South of the UK, we operate as all over the UK.</p>

				<p>If you share our passion for delivering top-notch services to our clients, have an eye for detail, and thrive in a dynamic and challenging work environment, we invite you to apply to join our team. We welcome individuals with diverse skills and backgrounds and provide a supportive and inclusive work environment where everyone can grow.</p>

				<h4>Our Opportunities:</h4>

				<p>We currently have no opportunities available.</p>

			</div>

			<div className="careers-freelance">

				<div className="careers-freelance-content">

					<h2>Freelance</h2>

					<p>We are always on the lookout for talented professionals to work with us on various projects. If you are looking to join one of our project teams to be a part of some of the incredible experiences we work on, then get in touch.</p>

					<button onClick={() => { showForm(); }}>Are you interested?</button>

					{(formIsVisible) ?

						<div className="careers-freelance-form">

							<h3>Freelance Form</h3>

							<div className="careers-freelance-form-input">
								<label>What should we call you? *</label>
								<input type="text" placeholder="Name" ref={nameRef} />
							</div>

							<div className="careers-freelance-form-input">
								<label>How can we reach you? *</label>
								<input type="email" placeholder="email" ref={emailRef} />
								<input type="tel" placeholder="phone" ref={phoneRef} />
							</div>

							<div className="careers-freelance-form-input">
								<label>Tell us about yourself: *</label>
								<textarea placeholder="What do you enjoy doing, your experience, favourite part of working, etc..." ref={aboutRef} />
							</div>

							<div className="careers-freelance-form-input">

								<label>What licences to you have?</label>

								<div className="careers-freelance-form-input-checkbox-group">

									{licences.map((licence, index) => (

										<div className="careers-freelance-form-input-checkbox" key={index}>
											<input type="checkbox" name="licences" value={licence.value} ref={licence.ref} />
											<label htmlFor={licence.value}>{licence.value}</label>
										</div>

									))}

								</div>

							</div>

							<div className="careers-freelance-form-input">
								<label>Is there anything you want to add?</label>
								<textarea placeholder="Aditional qualifications, awards etc..." ref={additionalRef} />
							</div>

							<button type="submit" onClick={() => { handleFormSubmit(); }}>SUBMIT</button>

						</div>

					:
					''}

				</div>

			</div>

			{(successIsVisible) ?

				<div className="careers-freelance-success">

					<h2>Thank you for your interest!</h2>

					<span>We will get back to you as soon as possible!</span>

				</div>

			:
			''}

			{(isLoading) ?
				<Loader />
			:
			''}

		</div>

    )

};

// Export Component
export default Careers;
