// Imports
import React from 'react';
import PropTypes from 'prop-types';
import './pagehero.scss';

// PageHero component
const PageHero = (props) => {

	// Position image
	let imageClass = "";
	if (props.title.toLowerCase() === "crew") {
		imageClass = "m-200";
	}
	if (props.title.toLowerCase() === "careers") {
		imageClass = "m-200";
	}
	if (props.title.toLowerCase() === "our team") {
		imageClass = "m-250l";
	}

	// Return component
	return (

		<div className="page-hero">
			
			{(imageClass === "") ?
				<picture>
					<source srcSet={props.image.webp} type="image/webp" />
					<source srcSet={props.image.png} type="image/png" />
					<img src={props.image.png} alt={props.title} />
				</picture>
			:
				<picture className={imageClass}>
					<source srcSet={props.image.webp} type="image/webp" />
					<source srcSet={props.image.png} type="image/png" />
					<img src={props.image.png} alt={props.title} className={imageClass} />
				</picture>
			}

			<div className="page-hero-cover">

				<h1>{props.title}</h1>

			</div>

		</div>

    )

};

PageHero.propTypes = {
    title: PropTypes.string.isRequired,
	image: PropTypes.object.isRequired,
}

// Export Component
export default PageHero;
