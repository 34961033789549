// Imports
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './newsarticle.scss';

// Components
import PageHero from '../../blocks/PageHero';
import Meta from '../../Meta';

// Utils
import newsArticles from '../../../utils/newsArticles';

// NewsArticle component
const NewsArticle = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    // Get & check article data
    const article = newsArticles.find(article => article.id === id);
    if (!article) {
        useEffect(() => {
            navigate('/page-not-found');
        }, []);
    }

	// Return component
	// TODO: Add description meta
	return (

		<div className="newsarticle">

			<Meta title={article.title} description={article.content[0] || ""} pathname={`news/${id}`} image="default" />

			<PageHero title="" image={{png: article.image.png, webp: article.image.webp}} />

            <div className="newsarticle-content">

                <h1>{article.title}</h1>

                <span>{article.date}</span>

                {article.content.map((paragraph, index) => (

                    <p key={index}>{paragraph}</p>

                ))}

                <div className="newsarticle-content-image">

                    <picture>
                        <source srcSet={article.image.webp} type="image/webp" />
                        <source srcSet={article.image.png} type="image/png" />
                        <img src={article.image.png} alt={article.title} />
                    </picture>

                </div>

            </div>

		</div>

    )

};

// Export Component
export default NewsArticle;
