// Imports
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './recentproject.scss';
import ImageGallery from "react-image-grid-gallery";

// Utils
import recentProjects from '../../../utils/recentProjects';

// Components
import Meta from '../../Meta';

// RecentProject component
const RecentProject = () => {

    const { title } = useParams();
    const navigate = useNavigate();

    // Get & check project data
    const project = recentProjects.find(project => project.link === title);
    if (!project) {
        useEffect(() => {
            navigate('/page-not-found');
        }, []);
    }

    // Create image array
    const imagesArray = [];
    project.gallery.forEach(image => {
        imagesArray.push({ alt: "", caption: "", src: image.png });
    });

	// Return component
	return (

		<div className="recentproject">

			<Meta title={title} description={project.content[0] || ""} pathname={`recent-project/${title}`} image="default" />

            <div className="recentproject-hero">

                <picture>
                    <source srcSet={project.hero.webp} type="image/webp" />
                    <source srcSet={project.hero.png} type="image/png" />
                    <img src={project.hero.png} alt={project.title} />
                </picture>

                <div className="recentproject-hero-cover">

                    <h1>{project.title}</h1>

                    <span>{project.date}</span>

                </div>

            </div>

            <div className="recentproject-content">

                {project.content.map((paragraph, index) => (

                    <p key={index}>{paragraph}</p>

                ))}

            </div>

            <div className="recentproject-gallery">

                <ImageGallery imgArray={imagesArray} columnWidth={200} gapSize={24} />

            </div>

		</div>

    )

};

// Export Component
export default RecentProject;