// Imports
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './home.scss';

// Utils
import recentProjects from '../../../utils/recentProjects';
import testimonials from '../../../utils/testimonials';

// Assets
import HomeHero from '../../../assets/photos/general/show-3-2400.png';
import HomeHeroWebP from '../../../assets/photos/general/show-3-2400.webp';
import LogoWhite from '../../../assets/logos/logo-white-360.png';
import LogoWhiteWebP from '../../../assets/logos/logo-white-360.webp';
import CrewImage from '../../../assets/photos/general/crew-1-800.png';
import CrewImageWebP from '../../../assets/photos/general/crew-1-800.webp';
import EventDesignImage from '../../../assets/photos/general/site-1-800.png';
import EventDesignImageWebP from '../../../assets/photos/general/site-1-800.webp';
import HireImage from '../../../assets/photos/mojo/mojo-1-800.png';
import HireImageWebP from '../../../assets/photos/mojo/mojo-1-800.webp';
import SafetyImage from '../../../assets/photos/general/safety-1-800.png';
import SafetyImageWebP from '../../../assets/photos/general/safety-1-800.webp';

// Components
import Testimonials from '../../blocks/Testimonials';
import LearnMore from '../../blocks/LearnMore';
import ContactCTA from '../../blocks/ContactCTA';
import Meta from '../../Meta';

// Home component
const Home = () => {

	const navigate = useNavigate();

	// Navigate to recent project
	const navigateToRecentProject = (project) => {

		navigate(`/recent-project/${project.link}`);

	}

	// Return component
	// TODO: Add description meta
	return (

		<div className="home">

			<Meta title="Home" description="Your top trump. The Ace in your hand. We are the group of people who will be by your side, day or night, to craft your vision into a physical reality. " pathname="home" image="default" />

			<div className="home-hero">

				<picture>
					<source type="image/webp" srcSet={HomeHeroWebP} />
					<source type="image/png" srcSet={HomeHero} />
					<img src={HomeHero} alt="Audley End Festival 2022" />
				</picture>

				<div className="home-hero-cover">

					<h1>We Are Viking</h1>

					<h2>We Do Things Differently</h2>

				</div>

			</div>

			<div className="home-statement">

				<div className="home-statement-logo">
					<picture>
						<source type="image/webp" srcSet={LogoWhiteWebP} />
						<source type="image/png" srcSet={LogoWhite} />
						<img src={LogoWhite} alt="Viking Logo" />
					</picture>
				</div>

				<div className="home-statement-text">

					<h3>Who Are We?</h3>

					<p>Your top trump. The Ace in your hand. We are the group of people who will be by your side, day or night, to craft your vision into a physical reality. Growing from the ground up, our management team is chosen based on their real-world experience in our field, not just a qualification. We work harder than any other team in the game.</p>

				</div>

			</div>

			<div className="recent-projects">

				<h3>Recent Projects</h3>

				<div className="recent-projects-wrapper">

					{recentProjects.map((project, index) => (

						<div className="recent-project" key={index} onClick={() => { navigateToRecentProject(project) }}>

							<picture>
								<source type="image/webp" srcSet={project.thumb.webp} width="400" height="400" />
								<source type="image/png" srcSet={project.thumb.png} width="400" height="400" />
								<img src={project.thumb.png} alt={project.title} width="400" height="400" />
							</picture>

							<div className="recent-project-cover">

								<h4>{project.title}</h4>

								<span>{project.date}</span>

							</div>

						</div>

					))}

				</div>

			</div>

			<Testimonials testimonials={testimonials} />

			<LearnMore content={{
				image: {
					png: CrewImage,
					webp: CrewImageWebP
				},
				title: "Crew",
				text: "We pride ourselves on investing not only money but time with our crew. Be it through official training(NPORS, IPAF, IOSH, NeBOSH, ACT CT, etc.) or time on the ground with our senior managers refining their skills. The crew are presentable, hard-working and switched on. They arrive on time, with the correct qualifications and kit for the job. Nothing is too boring, and nothing will ever be too crazy. We love giving the staff a challenge. Our core staff team work with us all year round across our portfolio of work, so they know how Viking gets the job done and the standard we expect.",
				link: "/what-we-do/crew"
			}} />

			<LearnMore isDark isFlipped content={{
				image: {
					png: HireImage,
					webp: HireImageWebP
				},
				webp: CrewImageWebP,
				title: "Hire",
				text: "Born out of frustration with specific sectors within the industry we started our own hire company. We hold a stock of over 400m of pit barrier, 15 miles of scaffolding tube, 5 JCB beaver packs(hydraulic post whacker), signage(emergency exits, water, medical, welfare, toilets, urinals, etc) and much more. Due to the number of events we work on, we also get a certain amount of buying power and can often save clients money by using our accounts. We can do last-minute hires anywhere in the country.",
				link: "/what-we-do/hire"
			}} />

			<LearnMore content={{
				image: {
					png: EventDesignImage,
					webp: EventDesignImageWebP
				},
				title: "Event Design",
				text: "We believe the first step in any event should be the layout and design of the site. Careful and thoughtful planning from the start can end up saving you a lot of money. Our site plans are drawn and perfected by the teams that will be running them, people with real-world experience in seeing these images come to life and how a box here and a line there interact. This real-world experience and knowledge will only impact your event in a positive way.",
				link: "/what-we-do/event-design"
			}}/>

			<LearnMore isDark isFlipped content={{
				image: {
					png: SafetyImage,
					webp: SafetyImageWebP
				},
				title: "Safety",
				text: "Safety is the cornerstone of all our operations. We have grown from being on the ground grafting with our hands and fully understanding safety. We know that the event industry has its quirks, and all our safety officers have developed from site crew. They are event people, not clipboard people.",
				link: "/what-we-do/safety"
			}}/>

			<ContactCTA />

		</div>

    )

};

// Export Component
export default Home;
