// Styles
import './index.scss';

// Packages
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Components
// Imports
import Container from './components/Container';
import ErrorBoundary from './components/ErrorBoundary';

const container = document.getElementById('application');
const root = createRoot(container);
root.render(<BrowserRouter>
	<ErrorBoundary>
		<Container/>
	</ErrorBoundary>
</BrowserRouter>);
