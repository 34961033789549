// Imports
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './whatwedo.scss';

// Components
import PageHero from '../../blocks/PageHero';
import Meta from '../../Meta';

// Assets
import HomeHero from '../../../assets/photos/general/show-4-2400.png';
import HomeHeroWebP from '../../../assets/photos/general/show-4-2400.webp';
import Crew from '../../../assets/photos/general/crew-1-600.png';
import CrewWebP from '../../../assets/photos/general/crew-1-600.webp';
import Site from '../../../assets/photos/general/site-1-600.png';
import SiteWebP from '../../../assets/photos/general/site-1-600.webp';
import Mojo from '../../../assets/photos/mojo/mojo-1-600.png';
import MojoWebP from '../../../assets/photos/mojo/mojo-1-600.webp';
import Safety from '../../../assets/photos/general/safety-1-600.png';
import SafetyWebP from '../../../assets/photos/general/safety-1-600.webp';
import General from '../../../assets/photos/general/general-1-600.png';
import GeneralWebP from '../../../assets/photos/general/general-1-600.webp';
import Consulting from '../../../assets/photos/general/stage-2-600.png';
import ConsultingWebP from '../../../assets/photos/general/stage-2-600.webp';

// WhatWeDo component
const WhatWeDo = () => {

	const whatWeDo = [
		{
			title: "Crew",
			link: "/what-we-do/crew",
			image: {
				png: Crew,
				webp: CrewWebP
			}
		},
		{
			title: "Event Design",
			link: "/what-we-do/event-design",
			image: {
				png: Site,
				webp: SiteWebP
			}
		},
		{
			title: "Hire",
			link: "/what-we-do/hire",
			image: {
				png: Mojo,
				webp: MojoWebP
			}
		},
		{
			title: "Safety",
			link: "/what-we-do/safety",
			image: {
				png: Safety,
				webp: SafetyWebP
			}
		},
		{
			title: "EMP & Sag",
			link: "/what-we-do/emp-sag",
			image: {
				png: General,
				webp: GeneralWebP
			}
		},
		{
			title: "Consulting",
			link: "/what-we-do/consulting",
			image: {
				png: Consulting,
				webp: ConsultingWebP
			}
		}
	];

	const navigate = useNavigate();

	// Navigate to service
	const navigateToService = (service) => {

		navigate(service.link);

	}

	// Return component
	// TODO: Add description meta
	return (

		<div className="what-we-do">

			<Meta title="What We Do" description="We absorb the operational stress of planning and running events, leaving you to focus on driving sales. " pathname="what-we-do" image="default" />

			<PageHero title="What We Do" image={{png: HomeHero, webp: HomeHeroWebP}} />

			<p>We absorb the operational stress of planning and running events, leaving you to focus on driving sales. We understand the complexities of stakeholder relationships and how they need to be managed to ensure your event can carry on for many years. We work hard with our suppliers to drive your budget costs down, not up, and we work harder than any other team currently in the market. Going green for doors late is never an option. We graft hard and always think ahead.</p>

			<div className="what-we-do-content">

				{whatWeDo.map((item, index) => (

					<div className="what-we-do-content-item" key={index} onClick={() => { navigateToService(item); }}>

						<picture>
							<source srcSet={item.image.webp} type="image/webp" />
							<source srcSet={item.image.png} type="image/png" />
							<img src={item.image.png} alt={item.title} />
						</picture>

						<div className="what-we-do-content-item-cover">

							<span>{item.title}</span>

						</div>

					</div>

				))}

			</div>

		</div>

    )

};

// Export Component
export default WhatWeDo;
