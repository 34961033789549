// Imports
import React from 'react';
import './empsag.scss';

// Components
import PageHero from '../../blocks/PageHero';
import ContactCTA from '../../blocks/ContactCTA';
import Meta from '../../Meta';

// Assets
import Hero from '../../../assets/photos/general/general-1-2400.png';
import HeroWebP from '../../../assets/photos/general/general-1-2400.webp';

// EMPSag component
const EMPSag = () => {

	// Return component
	// TODO: Add description meta
	return (

		<div className="empsag">

			<Meta title="EMP & Sag" description="We understand that event management plans (EMPs) and safety advisory groups (SAGs) are critical components of any successful live event in the United Kingdom. " pathname="what-we-do/emp-sag" image="default" />

			<PageHero title="EMP & Sag" image={{png: Hero, webp: HeroWebP}} />

			<div className="empsag-content">

				<p>We understand that event management plans (EMPs) and safety advisory groups (SAGs) are critical components of any successful live event in the United Kingdom. That's why we work closely with our clients to create comprehensive EMPs that comply with all relevant UK legislation and guidelines, and to engage with SAGs to ensure that all aspects of the event are safe and well-managed. The SAG process can be a minefield of egos and lack of knowledge, so let our team guide you through the process.</p>

				<p>Our experienced team has a deep understanding of the specific requirements of UK event management, including the responsibilities of event organisers under the Health and Safety at Work Act 1974 and the Management of Health and Safety at Work Regulations 1999. We also have extensive experience in working with local authorities and SAGs, including the police, fire and rescue services, and medical services, to ensure that all aspects of the event are planned and managed in accordance with UK health and safety regulations.</p>

				<p>We recognise that every event is unique, and we take a bespoke approach to creating EMPs that are tailored to the specific needs of each client and event. Our team of experts work closely with clients to identify potential hazards and risks, and to develop appropriate mitigation measures to ensure the safety of all attendees, crew, and contractors.</p>

				<p>In addition to our focus on safety, we also understand the importance of effective event management in creating a successful event. Our EMPs include detailed plans for all aspects of the event, from logistics and security to catering and entertainment. We work closely with our clients to ensure that their vision for the event is realised, while also adhering to all relevant UK legislation and guidelines.</p>

				<p>Overall, our focus on EMPs and SAGs is an integral part of our commitment to delivering exceptional events that are both enjoyable and safe for all attendees and staff. If you're looking for a team that prioritises safety and compliance, we invite you to contact us today to learn more about our event management services.</p>

			</div>

			<ContactCTA />

		</div>

    )

};

// Export Component
export default EMPSag;
