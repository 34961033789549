// Imports
import React from 'react';
import './pagenotfound.scss';

import HomeHero from '../../../assets/photos/general/pnf-2400.png';
import HomeHeroWebP from '../../../assets/photos/general/pnf-2400.webp';

// PageNotFound component
const PageNotFound = () => {

	// Return component
	return (

		<div className="pnf">

				<picture>
					<source type="image/webp" srcSet={HomeHeroWebP} />
					<source type="image/png" srcSet={HomeHero} />
					<img src={HomeHero} alt="Audley End Festival 2022" />
				</picture>

				<div className="pnf-cover">

					<h1>Oops...</h1>

					<h2>It looks like we can't find the page you are looking for.</h2>

					<p>Maybe the link has changed or the page has been removed.</p>

				</div>

			</div>

    )

};

// Export Component
export default PageNotFound;
